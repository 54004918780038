.chart-gauge {
  width: 200px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chart-color1 {
  fill: #4caf50;
}

.chart-color2 {
  fill: #ffc107;
}

.chart-color3 {
  fill: #f44336;
}

.needle, .needle-center {
  fill: #464A4F;
}

.needle-center {
  stroke: #464A4F;
}

.gwei-text {
  font-size: 13px;
  font-weight: bold;
  fill: #868788;
  text-transform: uppercase;
}