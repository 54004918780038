:root {
	--primary: #8C7AE6;  /* A softer purple */
	--secondary: #4CAF50;  /* Keep the green */
	--background: #121212;  /* Darker background */
	--surface: #1E1E1E;  /* Slightly lighter than background */
	--text: #FFFFFF;
	--subtext: #B0B0B0;  /* Lighter subtext for better readability */
	--accent: #FF6B6B;  /* Keep the accent */
	--button-hover: #7C6AD6;  /* Slightly darker than primary for button hover */
}

body {
	background-color: var(--background);
	color: var(--text);
}

.dustbin-app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.footer-wrapper {
	margin-top: 1rem;
	width: 100%;
	padding: 1rem 0;
}

.main-card {
	background-color: var(--surface);
	border: none;
	border-radius: 15px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.card-text {
	color: var(--subtext);
}

.connect-wallet-btn,
.sell-tokens-btn {
	background-color: var(--primary);
	border-color: var(--primary);
	border-radius: 25px;
	padding: 10px 20px;
	font-weight: bold;
	transition: all 0.3s ease;
	color: #fff;
}

.connect-wallet-btn:hover,
.sell-tokens-btn:hover {
	background-color: var(--button-hover);
	border-color: var(--button-hover);
	transform: translateY(-2px);
	box-shadow: 0 4px 10px rgba(140, 122, 230, 0.3);
}

.sell-tokens-btn-wrapper {
  margin-top: 1rem;
}

.sell-tokens-btn {
	width: 100%;
}

.account-card {
	background-color: var(--surface);
	border: 1px solid var(--primary);
}

.account-address {
	color: var(--text);
}

.refresh-btn,
.disconnect-btn,
.referral-btn {
	background-color: transparent;
	border: 2px solid var(--primary);
	color: var(--primary);
	border-radius: 50%;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;
}

.refresh-btn:hover,
.disconnect-btn:hover,
.referral-btn:hover {
	background-color: var(--primary);
	color: var(--text);
}

.section-title {
	color: var(--primary);
}

.token-list .list-group-item {
	background-color: rgba(255, 255, 255, 0.05);
	border-color: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	margin-bottom: 10px;
	transition: all 0.3s ease;
}

.token-list .list-group-item:hover {
	background-color: rgba(255, 255, 255, 0.1);
	transform: translateY(-2px);
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.token-list .list-group-item.active {
	background-color: var(--primary);
	border-color: var(--primary);
}

.token-value {
	background-color: var(--secondary);
	border-radius: 15px;
	padding: 5px 10px;
}

.no-tokens-card {
	background-color: var(--surface);
	border-color: var(--subtext);
}

.expected-eth-card {
	background-color: var(--surface);
	border-color: var(--secondary);
}

.expected-eth-amount {
	color: var(--secondary);
}

.sell-tokens-btn {
	background-color: var(--secondary);
	border-color: var(--secondary);
	position: relative;
	z-index: 10;
}

.sell-tokens-btn:hover {
	background-color: darken(var(--secondary), 10%);
	border-color: darken(var(--secondary), 10%);
}

.custom-spinner {
	color: var(--primary);
}

.custom-progress {
	background-color: var(--surface);
}

.custom-progress .progress-bar {
	background-color: var(--primary);
}

.custom-progress-bar {
	height: 30px;
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 5px;
	overflow: hidden;
  }
  
  .custom-progress-bar .progress-bar {
	background-color: var(--primary);
	transition: width 0.5s ease;
  }
  
  .custom-progress-bar .progress-bar-animated {
	animation: progress-bar-stripes 1s linear infinite;
  }
  
  @keyframes progress-bar-stripes {
	0% { background-position-x: 1rem; }
  }

.processing-card {
	background-color: rgba(30, 30, 30, 0.9);
	border: 1px solid rgba(140, 122, 230, 0.3);
	border-radius: 15px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.processing-card .card-title {
	font-size: 1.2rem;
	font-weight: bold;
	margin-bottom: 1rem;
}

.processing-card .card-body {
	padding: 1.5rem;
}

.processing-details {
	background-color: rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	padding: 1rem;
	font-size: 0.9rem;
	white-space: pre-wrap;
	word-break: break-word;
}

.details-toggle:hover {
	text-decoration: underline;
}

.text-subtext {
	color: var(--subtext);
}

.app-header {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;	/* Space between logo and title */
}

.app-logo {
	max-width: 40px;	/* Adjust this value to make the logo smaller */
	height: auto;
}

.app-title {
	margin: 0;	/* Remove default margin */
	font-size: 2rem;	/* Adjust as needed */
	color: var(--primary);
	font-weight: bold;
	text-transform: lowercase;
}

.app-title span {
	color: var(--secondary);
}

@media (max-width: 576px) {
	.app-header {
		flex-direction: column;
	}

	.app-logo {
		max-width: 50px;	/* Even smaller on mobile */
	}

	.app-title {
		font-size: 1.5rem;	/* Smaller font on mobile */
	}
}

.expected-eth-card,
.no-tokens-card {
	height: 100%;
	overflow-y: auto;
}

.token-selection-row {
	height: calc(50vh - 400px);
	min-height: 400px; /* Set a minimum height for the entire row */
	max-height: 500px;
}

.token-column {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.token-list-container, .no-tokens-container {
	height: 100%;
	overflow-y: auto; /* Allow scrolling if content exceeds min-height */
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 0.25rem;
	padding: 1rem;
	background-color: var(--surface);
}

.token-list {
	height: calc(100% - 40px);
	overflow-y: auto; /* Allow scrolling if needed */
	padding-right: 0.5rem;
}

.token-list-column {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.expected-eth-card {
	height: 100%; /* Make sure the card takes full height */
}

/* Customize scrollbar for token list */
.token-list::-webkit-scrollbar {
	width: 6px;
}

.token-list::-webkit-scrollbar-track {
	background: rgba(255, 255, 255, 0.1);
	border-radius: 3px;
}

.token-list::-webkit-scrollbar-thumb {
	background-color: rgba(255, 255, 255, 0.3);
	border-radius: 3px;
}

.token-list::-webkit-scrollbar-thumb:hover {
	background-color: rgba(255, 255, 255, 0.5);
}

.token-item.max-reached:not(.selected):hover,
.token-item.zero-value:hover {
  cursor: not-allowed;
  background-color: rgba(255, 0, 0, 0.1); /* Light red background */
}

.token-count-box {
  background-color: var(--surface);
  color: var(--text);
  padding: 0.375rem 0.75rem;
  font-size: 0.7rem;
	font-style: italic;
	white-space: nowrap;
}

.filter-input-group {
  display: flex;
  align-items: stretch;
}

.filter-input-group .input-group-text {
  width: 105px; /* Adjust this value as needed */
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.filter-input-group .form-control {
  flex: 1;
}

@media (max-width: 767px) {
	.gas-gauge-container {
		justify-content: center;
	}

  .footer-links {
    margin-bottom: 80px; /* Adjust this value based on your mobile button height */
  }

	.filter-input-group {
    flex-direction: column;
		border-radius: none;
  }

  .filter-input-group .input-group-text {
    width: 100%;
    justify-content: flex-start;
    padding: 0.375rem 0.75rem;
  }

  .filter-input-group .form-control {
    width: 100%;
  }

	.token-selection-row {
		min-height: auto; /* Remove minimum height on mobile */
	}

	.token-list-container, .no-tokens-container {
		min-height: 200px; /* Smaller minimum height on mobile */
	}

  .dustbin-app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .main-card .card-body {
    display: flex;
    flex-direction: column;
  }

	.main-card {
		margin-bottom: 1rem;
	}

  .token-selection-row {
    flex-grow: 1;
  }

  .token-list-container {
    height: auto;
    max-height: 300px;
  }

	.expected-eth-card {
		min-height: 200px;
		max-height: 500px;
	}

	.sell-tokens-btn-wrapper-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--surface);
    padding: 1rem;
    z-index: 1000;
  }

  .footer-wrapper {
		margin-top: auto;
		padding-bottom: 150px;
  }
}

.token-item {
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s ease;
}

.token-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.token-item.selected {
  background-color: rgba(76, 175, 80, 0.2); /* Green color with opacity */
  border-left: 4px solid #4CAF50; /* Green left border for emphasis */
}

.token-link {
  z-index: 1;
  position: relative;
}

.token-item .fa-chart-line,
.token-item .fa-external-link-alt
.token-item .fa-up-right-from-square,
a.token-link {
  color: #FFFFFF;
  font-size: 1rem;
  transition: color 0.2s ease-in-out;
}

.token-item strong {
	color: #fff;	
}

.token-item .fa-chart-line:hover,
.token-item .fa-external-link-alt:hover,
.token-item .fa-up-right-from-square:hover,
a.token-link:hover {
  color: #007bff;
}

.account-address {
	font-size: 0.875rem;
	}
.account-card .card-footer {
	background-color: transparent;
	border-top: none;
}

	.account-address {
		font-size: 0.875rem;
	}
	
	.custom-input-group {
		background-color: rgba(255, 255, 255, 0.1);
		border-radius: 25px;
		overflow: hidden;
	}

	.custom-input-group .input-group-text {
		background-color: #695ea2;
		font-weight: bold;
		border: none;
		color: var(--text);
	}
	
	.custom-input {
		background-color: transparent;
		border: none;
		color: var(--text);
		padding: 10px 15px;
	}
	
	.custom-input::placeholder {
		color: rgba(255, 255, 255, 0.5);
	}
	
	.custom-input:focus {
		background-color: rgba(255, 255, 255, 0.05);
		box-shadow: none;
		color: var(--text);
	}
	/* Customize toast appearance */
.Toastify__toast {
  background-color: #282c34;
  color: white;
}

.Toastify__toast--success {
  background-color: #28a745;
}

.Toastify__toast--error {
  background-color: #dc3545;
}

.Toastify__progress-bar {
  background-color: rgba(255, 255, 255, 0.7);
}
.tooltip {
  font-size: 0.875rem;
}

.tooltip-inner {
  background-color: #282c34;
  color: white;
  border: 1px solid #61dafb;
}

.bs-tooltip-top .arrow::before, 
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #61dafb;
}

.service-fee-notice,
.referrer-notice {
  font-size: 0.8rem;
  color: white;
  font-style: italic;
  text-align: center;
  margin: 0;
  padding: 0 1rem;
}

.referrer-notice {
	color: #999999;
}

.close-btn {
  font-size: 1.2rem;
  line-height: 1;
  opacity: 0.75;
  transition: opacity 0.15s ease-in-out;
}

.close-btn:hover {
  opacity: 1;
}

.processing-card .spinner-border {
  width: 1rem;
  height: 1rem;
  border-width: 0.1em;
}

.footer-links {
  font-size: 0.9rem;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.footer-links:hover {
  opacity: 1;
}

.footer-link {
  color: var(--subtext);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: var(--text);
  text-decoration: underline;
}


.stats-counter {
  padding: 15px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
	color: #808080;
}

.stats-counter div {
  margin-bottom: 10px;
}

.destination-wallet {
  font-size: 0.9em;
  margin-top: 0.5rem;
}

.edit-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

.edit-btn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Modal styling */
.modal-content {
  background-color: var(--surface);
  color: var(--text);
  border: 1px solid rgba(140, 122, 230, 0.3);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.modal-header {
  border-bottom: 1px solid rgba(140, 122, 230, 0.2);
  padding: 1.5rem;
}

.modal-title {
  color: var(--text);
  font-weight: bold;
}

.modal-header .btn-close {
  color: var(--text);
  text-shadow: none;
  opacity: 0.7;
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal-header .btn-close:hover {
  opacity: 1;
}

.modal-body {
  padding: 1.5rem;
}

.modal-footer {
  border-top: 1px solid rgba(140, 122, 230, 0.2);
  padding: 1.5rem;
}

/* Form styling within modal */
.modal-body .form-label {
  color: var(--text);
  margin-bottom: 0.5rem;
}

.modal-body .form-control {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(140, 122, 230, 0.3);
  color: var(--text);
  border-radius: 8px;
  padding: 0.75rem;
}

.modal-body .form-control:focus {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: var(--primary);
  box-shadow: 0 0 0 0.2rem rgba(140, 122, 230, 0.25);
  color: var(--text);
}

.modal-body .form-control::placeholder {
  color: var(--subtext);
}

/* Modal buttons */
.modal .btn-secondary {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
  color: var(--text);
}

.modal .btn-secondary:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.3);
  color: var(--text);
}

.modal .btn-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--text);
}

.modal .btn-primary:hover {
  background-color: var(--button-hover);
  border-color: var(--button-hover);
  transform: translateY(-1px);
  box-shadow: 0 4px 10px rgba(140, 122, 230, 0.3);
}

/* Center modal vertically */
.modal {
  display: flex !important;
  align-items: center;
  padding: 0 !important;
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
  margin: auto;
	max-width: 600px;
	width: 90%;
}

/* Optional: Add a smooth transition */
.modal.fade .modal-dialog {
  transform: translate(0, -50px);
  transition: transform 0.3s ease-out;
}

.modal.show .modal-dialog {
  transform: none;
}